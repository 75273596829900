import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAiPyCyjlRx775vzZ9QQOQBmvNMW3Xw5sg",
  authDomain: "trulleclients.firebaseapp.com",
  projectId: "trulleclients",
  storageBucket: "trulleclients.appspot.com",
  messagingSenderId: "942313012245",
  appId: "1:942313012245:web:4f4be8394e11ecb167221c",
  measurementId: "G-2F05CCMMGH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);