import { Link, useNavigate } from 'react-router-dom'
import './dashboard.css'
import { useEffect, useState } from 'react'
import { auth } from '../config/firebase'
import { signOut } from 'firebase/auth'

export default function Dashboard(){
    
    const history = useNavigate()
    useEffect(()=>{        
        if(sessionStorage.getItem('login')!='true'){
            history('/admin/login')
        }
    },[])

    const [counts, setCounts] = useState({
        products:0,
        stories:0,
        tests:0,
    })
    useEffect(()=>{
        // fetch(`${process.env.REACT_APP_API_URL}/counts`)
        fetch(`${process.env.React_APP_API_URL}/counts`)
        .then(res=>res.json())
        .then(data=>{
            setCounts({
                products:data.products[0].products,
                stories:data.story[0].story,
                tests:data.testimonial[0].testimonial,
            })
        })
    },[])
    return(
        <div className='dashWrapper'>
            <div className='CMSLable'>
                <h3>Hi,</h3>
                <div className='CMSLableBtn'>
                    <button onClick={()=>(signOut(auth),sessionStorage.setItem('login','false'), window.location.reload())}>Log Out</button>
                    <button onClick={()=>history('/')}>Go To Site</button>
                </div>    
            </div>
            <div className='CMSHeader'>
                <h2>Dashboard</h2>
            </div>
            <div className='dashboardContent'>
                <div className='dashCount'>
                    <div className='countContainer'>
                        <p>Products</p>
                        <h1>{counts.products}</h1>
                    </div>
                    <div className='countContainer'>
                        <p>Stories</p>
                        <h1>{counts.stories}</h1>
                    </div>
                    <div className='countContainer'>
                        <p>Testimonials</p>
                        <h1>{counts.tests}</h1>
                    </div>
                </div>
                <div className='dashBtnContainer'>
                    <Link to={'/admin/productListing'}><button>Products</button></Link>
                    <Link to={'/admin/storyListing'}><button>Stories</button></Link>
                    <Link to={'/admin/testListing'}><button>Testimonials</button></Link>
                </div>
            </div>
        </div>
    )
}