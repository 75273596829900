import './footer.css'
import { Link } from 'react-router-dom';

export default function Footer(){
    return(
        <div className='footerWrapper'>
            <div className='footerContainer'>
                <div className='footerColumn footerBorder'>
                    <Link to={'/'}>
                        <div className='footerLogo'>
                            <img src='./images/footerLogo.svg' alt='APPL'/>
                        </div>
                    </Link>
                    <div className='footerAddress'>
                        <h2>Address:</h2>
                        <p className='addressline'>
                        No.4/371-A, Hosur Town ,Moovender Nagar, 
                        Hosur,Krishnagiri Dist-635109
                        </p>
                        <p>Phone:  <span>7305056781/2/3/4</span></p>
                    </div>
                </div>
                <div className='footerColumn footer2'>
                    <div className='footermenu'>
                        <Link to={'/'}>
                            <p>Home</p>
                        </Link>
                        <Link to={'/estimator'}>
                            <p>Estimator</p>
                        </Link>
                        {/* <p>Offer</p> */}
                        <Link to={'/solarkits'}>
                            <p>Solar Kits</p>
                        </Link>
                        <Link to={'/products'}>
                            <p>Products</p>
                        </Link>
                        <Link to={'/story'}>
                            <p>Story</p>
                        </Link>
                        <Link to={'/about'}>
                            <p>About Us</p>
                        </Link>
                    </div>
                    <div className='footerSub'>
                        <Link to='/products' state={{footerChoice:"light"}}>Solar Street Light</Link>
                        <Link to='/products' state={{footerChoice:"pump"}}>Solar Water Pump</Link>
                        <Link to='/products' state={{footerChoice:"heater"}}>Solar Water Heater</Link>
                        <Link to='/products' state={{footerChoice:"ups"}}>UPS & Battery</Link>
                    </div>
                </div>
                <div className='footerColumn footer3'>
                    <h2>Follow Us On</h2>
                    <div className='icons'>
                        <div className='icon'>
                            <img src='./images/linkedin.svg' alt='in'/>
                        </div>
                        <div className='icon'>
                            <img src='./images/insta.svg' alt='in'/>
                        </div>
                        <div className='icon'>
                            <img src='./images/fb.svg' alt='in'/>
                        </div>
                        <div className='icon'>
                            <img src='./images/share.svg' alt='in'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}