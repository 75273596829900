import { useState } from 'react';
import './login.css';
import { auth } from '../config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export default function LogPage(){
    const navigate = useNavigate()
    const [user, setUser] = useState('')
    const [pass, setPass] = useState('')

    const checkAuth = async(e) =>{
        e.preventDefault()
        try{
            const userdetails = await signInWithEmailAndPassword(auth, user, pass);
            sessionStorage.setItem('login', 'true');
            sessionStorage.setItem('user', user);
            navigate('/admin/dashboard')
        }catch (error){
            alert("Wrong Credential")
        }
    }
    return(
        <div className='cms'>            
            <div className='loginPage'>
            <h1>Admin Login</h1>
                <form onSubmit={checkAuth}>
                    <div className='inputAuth'>
                        <input
                        type='text'
                        placeholder='Username'
                        name='user'
                        value={user}
                        onChange={(e)=>setUser(e.target.value)}
                        required
                        />
                    </div>
                    <div className='inputAuth'>
                        <input
                        type='password'
                        placeholder='Password'
                        name='pass'
                        value={pass}
                        onChange={(e)=>setPass(e.target.value)}
                        required
                        />
                    </div>
                    <div className='inputAuth'>
                        <input
                        type='submit'
                        value={'Submit'}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}